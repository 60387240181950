import { Card } from 'antd';
import React from 'react';
import { AiOutlineLeft } from 'react-icons/ai';

interface Props extends Omit<React.HTMLAttributes<HTMLDivElement>, 'title'> {
  title: React.ReactNode;
  onBack?: () => any;
  showBack?: boolean;
  style?: React.CSSProperties;
}

export default function PageHeaderBar(p: Props) {
  const { title, showBack = true, onBack, style = {} } = p;
  return (
    <Card style={style} styles={{ body: { padding: 0 } }}>
      <div className="px-6 py-4 flex items-center shadow-md w-full">
        {showBack && (
          <>
            <div
              className="text-[#2f88ff] flex items-center select-none cursor-pointer hover:opacity-[0.8] active:opacity-[0.85]"
              onClick={onBack}>
              <AiOutlineLeft size="14" />
              <span style={{ marginLeft: 4 }}>返回</span>
            </div>
            <div className="mx-6 w-[1px] h-[14px] bg-[#e0e0e0]" />
          </>
        )}
        <div className="font-semibold text-base leading-[22px] text-black dark:text-white">
          {title}
        </div>
      </div>
    </Card>
  );
}
